<template>
    <div>
        <div class="measurableHide">
            <Document class="documents-page">
                <div
                    v-for="(item, index) in documentItems"
                    :key="'ws-map-items-' + index"
                    class="item activity document-items pointer"
                    :style="{
                        color: item.style?.color,
                        fontSize: item.style?.font_size,
                        lineHeight: style.font_space + 'em',
                        paddingTop: item.type === 'divider' ? paddingValue(item.data.top_padding) + 'em' : '',
                        paddingBottom: measurablePaddingBottom(item),
                    }"
                >
                    <component :is="`${item.type}-preview`.replace(/_/g, '-')" :item="item" />
                </div>
            </Document>
        </div>

        <Document
            v-for="(page, pIndex) in documents"
            :key="'document-page-' + pIndex"
            class="documents-page render-page"
            :class="{
                show_answer_key: answerable && document.show_answer_key,
            }"
            :index="pIndex"
            :hide-head="Boolean(pIndex)"
            @goto="handleGoto"
        >
            <div
                v-for="(item, iIndex) in page.body.items"
                v-show="!item.hide"
                :id="'preview-' + item.id"
                :key="'visible-items-' + pIndex + '-' + iIndex"
                class="item activity position-relative"
                :style="{
                    fontSize: item.style?.font_size,
                    lineHeight: style.font_space + 'em',
                    paddingTop: item.type === 'divider' ? `${paddingValue(item.data.top_padding)}em` : '',
                    paddingBottom: `${getWorksheetPaddingBottom(item, page.body.items, iIndex)}`,
                }"
                @click="goto('goto-id-' + item.id, 'goto-container', item)"
            >
                <span :id="'anchor-' + item.id" class="position-absolute" style="top: -100px"></span>
                <component
                    :is="`${item.type}-preview`.replace(/_/g, '-')"
                    :item="item"
                    @optionItemClicked="optionItemClicked"
                    @setSubtitle="(val) => (item.data.subtitle = val)"
                />
            </div>
        </Document>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Document from './document.vue'
import MultipleChoicePreview from '../components/previews/MultipleChoicePreview.vue'
import OpenResponsePreview from '../components/previews/OpenResponsePreview.vue'
import HandwritingPreview from '../components/previews/HandwritingPreview.vue'
import FillInTheBlankPreview from '../components/previews/FillInTheBlankPreview.vue'
import MatchingPreview from '../components/previews/MatchingPreview.vue'
import WordScramblePreview from '../components/previews/WordScramblePreview.vue'
import InstructionPreview from '../components/previews/InstructionPreview.vue'
import DividerPreview from '../components/previews/DividerPreview.vue'
import BlankSpacePreview from '../components/previews/BlankSpacePreview.vue'
import SectionHeaderPreview from '../components/previews/SectionHeaderPreview.vue'
import WordBankPreview from '../components/previews/WordBankPreview.vue'
import PageBreakPreview from '../components/previews/PageBreakPreview.vue'
import BasicTextPreview from '../components/previews/BasicTextPreview.vue'
import ChecklistPreview from '../components/previews/ChecklistPreview.vue'

export default {
    name: 'Pages',
    components: {
        Document,
        MultipleChoicePreview,
        ChecklistPreview,
        BasicTextPreview,
        OpenResponsePreview,
        HandwritingPreview,
        FillInTheBlankPreview,
        MatchingPreview,
        WordScramblePreview,
        InstructionPreview,
        DividerPreview,
        BlankSpacePreview,
        SectionHeaderPreview,
        WordBankPreview,
        // BlankSpacePreview,
        PageBreakPreview,
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            numbersAreVisible: 'document/numbersAreVisible',
            documents: 'document/documents',
            documentItems: 'document/documentItems',
            style: 'document/documentStyle',
            answerable: 'document/answerable',
        }),
    },
    methods: {
        itemComponentName(type) {
            return 'document-' + type.replace(/_/g, '-')
        },
        goto(refName, containerRef, item) {
            this.$store.dispatch('document/setWidgetStatus', {
                openHeader: false,
                focusedItem: item,
            })

            this.$emit('goto', { ref: refName, container: containerRef })
        },
        handleGoto(e) {
            this.$store.dispatch('document/setWidgetStatus', {
                openHeader: true,
                focusedItem: undefined,
            })

            this.$emit('goto', { ref: e.ref, container: e.container })
        },
        paddingValue(paddingValue) {
            let spacing = this.style.item_spacing
            paddingValue = parseFloat(paddingValue)

            return paddingValue * (spacing * (1 / (1 + paddingValue) + 0.5))
        },
        getWorksheetPaddingBottom(item, items, index) {
            if (item.type === 'handwriting') return items[index + 1]?.type === 'handwriting' ? '0.1em' : '1em'

            return item.type !== 'divider'
                ? this.style.item_spacing + 'em'
                : this.paddingValue(item.data.bottom_padding) + 'em'
        },
        optionItemClicked(data) {
            this.$store.dispatch('document/setWidgetStatus', {
                openHeader: false,
                focusedItem: data,
            })
        },
        measurablePaddingBottom(item) {
            switch (item.type) {
                case 'handwriting':
                    return '0.1em'
                case 'divider':
                    return this.paddingValue(item.data.bottom_padding) + 'em'
                default:
                    return this.style.item_spacing + 'em'
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.documents-page {
    .item {
        scroll-margin-top: 1.25em;

        .question {
            display: flex;
            margin-bottom: 0.5em;
        }
    }
}
</style>
