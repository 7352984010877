import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

interface Data {
    titleFontSize: number
    instructionFontSize: number
    studentInfoFontSize: number
    allowance: number
}

export default defineComponent({
    name: 'WithDocumentHeader',
    props: {
        hideHead: {
            type: Boolean,
            default: false,
        },
        headerHeight: {
            type: Number,
            default: 2,
            required: false,
        },
        index: {
            type: Number,
            default: -1,
        },
    },
    data(): Data {
        return {
            titleFontSize: 0,
            instructionFontSize: 0,
            studentInfoFontSize: 0,
            allowance: 5,
        }
    },
    computed: {
        document(): any {
            return this.$store.state.document as any
        },
        ...mapGetters({
            instructions: 'document/documentInstructions',
            flashcard: 'document/flashcard',
            instructionStyle: 'document/instructionsStyle',
            omni_student: 'document/omniStudent',
            style: 'document/documentStyle',
            isFlashcard: 'document/isFlashcard',
            isBingo: 'document/isBingo',
        }),
        omniStudent: {
            get() {
                return this.omni_student
            },
            async set(value: any) {
                await this.$store.dispatch('document/setOmniStudent', parseInt(value))
            },
        },
        showStudentInfo(): boolean {
            if (this.isBingo) {
                return true
            } else {
                if (this.omniStudent) {
                    return true
                } else {
                    return this.index === 0 && !this.hideHead
                }
            }
        },
        hasTitleBox(): boolean {
            return !this.hideHead && this.document.title_visible && this.showStudentInfo
        },
        hasStudentInfoBox(): boolean {
            return (
                (!this.hideHead || this.omniStudent) &&
                this.document.student_fields &&
                this.showStudentInfo &&
                this.document.student_info_visible
            )
        },
        hasInstructionBox(): boolean {
            return (
                this.document.entity_type !== 'worksheet' &&
                this.instructions.visible &&
                !this.hideHead &&
                this.showStudentInfo
            )
        },
        headerHeightInPixels(): number {
            if (this.bodyHeight > 0) {
                return this.calculatedHeaderHeight
            }
            return (this.headerHeight * 96) as number
        },
        titleMaxHeight(): number {
            const calculatedHeight =
                (this.headerHeightInPixels - this.getInstructionPaddingBorderHeight) * this.headerSharingRatio

            if (this.isBingo) return calculatedHeight - this.style.titleBottomPaddingHeight

            return calculatedHeight
        },
        instructionMaxHeight(): number {
            return (
                (this.headerHeightInPixels - this.getInstructionPaddingBorderHeight) * this.headerSharingRatio -
                this.getInstructionTopMargin -
                this.allowance
            )
        },
        headerSharingRatio(): number {
            if (!this.hasInstructionBox || !(this.hasTitleBox || this.hasStudentInfoBox)) return 1

            return 0.5
        },
        bodyHeight(): number {
            const body = this.$refs.documentBody as HTMLElement
            if (!body) return 0

            return body.getBoundingClientRect()?.height
        },
        usesHeaderAutoResize(): boolean {
            return this.isBingo || (this.isFlashcard && this.flashcard.page_setup.flashcard_type === '3x5')
        },
        getTitleBottomPaddingHeight(): string {
            return `${this.style.titleBottomPaddingHeight}px`
        },
        calculatedHeaderHeight(): number {
            let documentHeight = this.style.documentHeight //inches
            if (this.isFlashcard) {
                if (this.flashcard.page_setup.flashcard_type === '3x5') {
                    documentHeight = this.style.threeByFive.documentHeight //inches
                }
            }

            return documentHeight * 96 - this.bodyHeight
        },
        getInstructionPaddingBorderHeight(): number {
            if (this.isFlashcard && this.flashcard.page_setup.flashcard_type === '3x5') {
                return this.style.threeByFive.instructionPaddingBorderHeight
            }
            return this.style.instructionPaddingBorderHeight
        },
        getInstructionTopMargin(): number {
            if (this.isFlashcard && this.flashcard.page_setup.flashcard_type === '3x5') {
                return this.style.threeByFive.instructionTopMargin
            }
            return 0
        },
    },
    watch: {
        document: {
            immediate: true,
            deep: true,
            handler() {
                this.$nextTick(this.adjustFontSizes)
            },
        },
        instructionStyle: {
            immediate: true,
            deep: true,
            handler() {
                this.$nextTick(this.adjustFontSizes)
            },
        },
    },
    mounted() {
        this.titleFontSize = this.document.title_font_size
        this.studentInfoFontSize = this.document.student_info_font_size
        this.instructionFontSize = this.instructionStyle?.font_size

        this.$nextTick(this.adjustFontSizes)
    },
    updated() {
        this.$nextTick(this.adjustFontSizes)
    },
    methods: {
        calculateFontSize(element: any, maxSize: number, minSize: number, maxHeight: number): number {
            if (!element) return maxSize

            let fontSize = maxSize
            element.style.fontSize = `${fontSize}px`

            while (element.clientHeight >= maxHeight && fontSize > minSize) {
                fontSize--
                element.style.fontSize = `${fontSize}px`
            }

            return fontSize
        },
        adjustFontSizes(): void {
            if (
                (!this.isBingo && !this.isFlashcard) ||
                (this.isFlashcard && this.flashcard.page_setup.flashcard_type !== '3x5')
            )
                return

            if (!this.$refs.docHeader) return

            if (this.hasTitleBox) {
                this.titleFontSize = this.calculateFontSize(
                    this.$refs.docTitle,
                    this.document.title_font_size,
                    this.style.minFontSize,
                    this.titleMaxHeight,
                )
            }

            if (this.hasStudentInfoBox) {
                this.studentInfoFontSize = this.calculateFontSize(
                    this.$refs.docStudentInfo,
                    this.document.student_info_font_size,
                    this.style.minFontSize,
                    this.titleMaxHeight,
                )
            }

            if (this.hasInstructionBox) {
                this.instructionFontSize = this.calculateFontSize(
                    this.$refs[`instructions-${this.index}`],
                    this.instructionStyle?.font_size,
                    this.style.minFontSize,
                    this.instructionMaxHeight,
                )
            }
        },
    },
})
